<template>
  <v-row v-if="dialogActivate.value" justify="center">
    <v-dialog
      v-model="dialogActivate.value"
      scrollable
      origin="center left"
      :persistent="true"
      :retain-focus="false"
    >
      <v-form ref="form" @submit.prevent="submitForm" v-model="formValid">
        <v-card class="modal">
          <v-card-title class="modal-title">{{ title }}</v-card-title>

          <v-divider></v-divider>

          <v-card-text class="modal-content">
            <v-row v-if="!dialogActivate.isEdit">
              <v-col cols="12" class="content-responsive">
                <v-autocomplete
                  v-model="searchModel"
                  :search-input.sync="searchInput"
                  :placeholder="text.busca_produtor_grupo"
                  outlined
                  hide-no-data
                  :no-data-text="text.nenhum_registro_selecionado_tabelas"
                  :hint="text.informativo_usuario_nao_encontrado_busca"
                  persistent-hint
                  :items="searchItems"
                  :loading="loadingSearch"
                  no-filter
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="!dialogActivate.isEdit">
              <v-col cols="12" md="3" class="content-responsive">
                <v-btn color="primary" @click="newUser()">{{
                  text.novo_convidado
                }}</v-btn>
              </v-col>
            </v-row>

            <div v-if="showForm">
              <v-container>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      outlined
                      v-model="userForm.nome"
                      :error-messages="errors.nome"
                      :rules="nameRules"
                      label="Nome completo *"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-text-field
                      outlined
                      v-model="userForm.cpf"
                      :rules="cpfRules"
                      :error-messages="errors.cpf"
                      v-mask="masks.cpf"
                      label="CPF *"
                      :disabled="operationMode === 'update'"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-text-field
                      outlined
                      v-model="userForm.email"
                      :rules="emailRules"
                      :error-messages="errors.email"
                      label="E-mail *"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-text-field
                      outlined
                      :error-messages="errors.telefone"
                      v-model="userForm.telefone"
                      v-mask="masks.phone"
                      label="Telefone Fixo"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-text-field
                      outlined
                      v-model="userForm.celular"
                      :error-messages="errors.celular"
                      :rules="phoneRules"
                      v-mask="masks.phone"
                      label="Celular *"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-layout justify-end class="button-content">
              <v-btn
                @click="closeDialog"
                class="button-cancel"
                depressed
                color="#FFF"
                >{{ $t("buttons.cancel") }}</v-btn
              >
              <v-btn
                type="submit"
                :disabled="!formValid || operationMode === 'none'"
                color="primary"
                >{{ $t("buttons.confirm") }}</v-btn
              >
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import _ from "lodash";
import { validateCPF } from "@/modules/core/helpers/cpfValidator";
import MasksMixin from "@/modules/core/mixins/masksMixin";
import UserSindaService from "@/modules/inviteds/service/UserSindaService";
import InviteService from "@/modules/invite/service/InviteService";
import text from "./text";

import { mapActions } from "vuex";

export default {
  props: {
    dialogActivate: {
      type: Object,
      required: true
    },
    fnValidateUser: {
      type: Function,
      required: false
    }
  },

  mixins: [MasksMixin],

  data: () => ({
    text: text,
    title: text.titulo_novo_convidado,
    showForm: false,
    formValid: false,
    searchModel: null,
    searchInput: "",
    searchItems: [],
    loadingSearch: false,
    operationMode: "none", // none | new | update
    userForm: {
      id: "",
      nome: "",
      cpf: "",
      email: "",
      telefone: "",
      celular: ""
    },
    nameRules: [v => !!v || "O campo nome é obrigatório"],
    cpfRules: [
      v => !!v || "O campo CPF é obrigatório",
      v => validateCPF(v) || "O campo CPF não é válido"
    ],
    emailRules: [
      v => !!v || "O campo e-mail é obrigatório",
      v => /.+@.+\..+/.test(v) || "O campo e-mail não é válido"
    ],
    phoneRules: [
      v => !!v || "O campo telefone fixo é obrigatório",
      v => v?.length > 13 || "O campo telefone fixo não é válido"
    ],
    errors: {
      nome: [],
      cpf: [],
      email: [],
      telefone: [],
      celular: []
    }
  }),

  watch: {
    searchInput() {
      if (!this.searchInput) {
        return;
      }
      this.search();
    },
    searchModel() {
      if (!this.searchModel) {
        return;
      }
      this.userForm = this.searchModel;
      this.operationMode = "update";
      this.searchItems = [];
      this.showUserForm();
    },
    "dialogActivate.value": function() {
      this.onToggleDialog();
    }
  },

  methods: {
    ...mapActions(["toggleSnackbar"]),
    onToggleDialog() {
      if (this.dialogActivate.value) {
        this.onOpenDialog();
      }
    },
    onOpenDialog() {
      this.clear();
      if (this.dialogActivate.isEdit) {
        this.$nextTick(() => {
          this.startEditMode();
        });
      }
    },
    startEditMode() {
      this.userForm = this.dialogActivate.initialData;
      this.showForm = true;
      this.operationMode = "update";
    },
    closeDialog() {
      this.clear();
      this.$emit("onCloseDialog");
    },
    clear() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
      this.showForm = false;
      this.userForm = {
        id: "",
        nome: "",
        cpf: "",
        email: "",
        telefone: "",
        celular: ""
      };
      this.searchItems = [];
      this.operationMode = "none";
    },
    newUser() {
      this.clear();
      this.showForm = true;
      this.operationMode = "new";
    },
    mountAutocompleteObject(item) {
      return {
        text: item.nome ? item.nome : "Não identificado",
        value: item
      };
    },
    async requestUserByFilter(filter) {
      this.loadingSearch = true;
      this.clear();

      try {
        const response = await UserSindaService.getUserByFilter(filter);

        this.searchItems = response.data.results.map(
          this.mountAutocompleteObject
        );
      } catch (error) {
        this.toggleSnackbar({
          text:
            error.response?.data?.detail ?? "Ocorreu um erro, tente novamente",
          type: "error"
        });
      }

      this.loadingSearch = false;
    },

    showUserForm() {
      this.showForm = true;
    },
    // Do not use arrow functions
    search: _.debounce(function() {
      this.requestUserByFilter(this.searchInput);
    }, 800),

    async validateForm() {
      if (!this.$refs.form.validate()) {
        return false;
      }
      try {
        if (this.fnValidateUser) {
          await this.fnValidateUser(this.userForm);
          return true;
        }
        await InviteService.validateForm(this.userForm);
        return true;
      } catch (error) {
        if (error.data?.detail) {
          this.toggleSnackbar({
            text: error.data.detail ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
          return false;
        }
        this.errors = {
          ...this.errors,
          ...error.data
        };
        return false;
      }
    },

    async submitForm() {
      const formValid = await this.validateForm();
      if (formValid) {
        this.$emit("inviteUser", {
          data: this.userForm,
          isEdit: this.dialogActivate.isEdit
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/components";

@include dialog;
@include primary-button;

.button-cancel {
  margin-right: 20px;
}

@media screen and (max-width: 960px) {
  .modal-title {
    text-align: center;
    word-break: break-word;
  }

  .content-responsive {
    display: flex;
    justify-content: center;
  }

  .button-content {
    display: flex;
    flex-direction: column;
    justify-content: center !important;

    .button-cancel {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}
</style>
